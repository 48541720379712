


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SuccessRedirectComponent extends Vue {
  @Prop({ required: true })
  public header!: string;

  @Prop({ default: 5 })
  public time!: number;

  public countdown = 2;

  @Prop({ required: true })
  public url!: string;

  public mounted() {
    this.countdown = this.time;
    this.count();
  }

  public count() {
    if (this.countdown === 0) {
      this.navigate();
    }
    if (this.countdown > 0) {
      setTimeout(() => {
        this.countdown -= 1;
        this.count();
      }, 1000);
    }
  }

  public navigate() {
    this.$redirect(this.url, false);
  }
}
